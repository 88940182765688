<template>
    <div class="row justify-elements-center pt-4" id="barraServicos">
        <div class="wrap-barra-servicos">
            <div class="card mx-md-2" v-for="servico in servicos" v-bind:key="servico.titulo">
                <a :href="servico.link" target="_blank">
                    <div class="card-body justify-content-center">
                        <i :class="servico.icone"></i>
                        <p class="card-title" >{{servico.titulo}}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'barraServicos',
    data (){
        return {
            servicos: [  
            {
                titulo: "Periódicos UNINOVE",
                icone: "bi bi-journal-medical",
                link: "https://periodicos.uninove.br/"
            },
            // {
            //     titulo: "Vídeos uninove",
            //     icone: "bi bi-collection-play",
            //     link: ""
            // },
            {
                titulo: "Seminários",
                icone: "bi bi-file-earmark-play",
                link: "https://www.uninove.br/biblioteca-seminarios-e-producoes-de-encontros"
            },
            {
                titulo: "Manual para trabalhos acadêmicos",
                icone: "bi bi-journal-check",
                link: "https://assets.uninove.br/Biblioteca/Trabalhos-Acad%C3%AAmicos-Manual.pdf?_ga=2.244841349.1678759175.1628531886-510449272.1621975059"
            },
            {
                titulo: "Editora UNINOVE",
                icone: "bi bi-book",
                link: "https://www.uninove.br/biblioteca-ebooks"
            },
            // {
            //     titulo: "Treinamento On-line",
            //     icone: "bi bi-ui-checks",
            //     link: ","
            // },
            {
                titulo: "Fale com a Biblioteca",
                icone: "bi bi-envelope",
                link: "mailto:biblioteca10@uninove.br"
            }]
        }
    }
}
</script>

<style scoped>
    a {
        color: initial;
        text-decoration: none;
    }
    a:hover {
        color: initial;
        text-decoration: none;
    }
    #barraServicos{
        justify-content: center;
        width: 100%;
    }
    @keyframes slideInFromLeft {
        0% {
            transform: translatey(-50%);
        }
        100% {
            transform: translateX(0);
        }
    }
    .wrap-barra-servicos{
        position: relative;
        /*top: -50px;*/
        display: flex;
        /* background-color: #fff; */
        place-content: center;
        width: auto;
        /* border-radius: 15px;
        box-shadow: var(--shadow) 0px 3px 8px; */
    }
    .card {
        width: 10rem;
        background-color: rgba(0,0,0,0) !important;
        border: 1px solid rgba(0,0,0,0);
        animation: 1s ease-out 0s 1 slideInFromLeft;
        background: #333;
        padding: 30px 0px;
    }
    .card:hover {
        cursor: pointer;
        box-shadow: var(--shadow) 0px 3px 8px;
    }
    .card-body{
        text-align: center;
        padding: 0px !important;
    }
    i {
        font-size: 35px;
    }
    .card-title {
        font-size: 12px;
        font-weight: bold;
    }
@media screen and (max-width: 900px){
    .card-title {
        font-size: 12px !important;
    }
    .card {
        width: 5rem;
        padding: 0px !important
    }
    .wrap-barra-servicos{
        max-width: 100%;
        position: initial;
        top: 0px;
        display: flex;
        flex-wrap: wrap;
        background-color: transparent;
        place-content: center;
        width: auto;
        border-radius: 0px;
        box-shadow: rgb(255 255 255 / 50%) 0px 3px 8px;
    }
}


</style>

