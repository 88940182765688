<template>
  <div class="home" style="background-color: var(--fundo-primo);">

      <barraServicos></barraServicos>
      <iconesApps></iconesApps>

  </div>
</template>

<script>
import barraServicos from '@/components/BarraServicos.vue'
import iconesApps from '@/components/iconesApps.vue'

export default {
    name: 'Home Produção',
    components: {
        iconesApps,
        barraServicos
    }
}
</script>
