<template>
    <div class="container">
        <h2 style="font-weight: bolder;">Informação e conhecimento disponíveis o tempo todo para você!</h2>

        <br>
    <div class="row mt-3 row-cols-1 row-cols-lg-3 justify-content-md-center">
        <div class="card card-custom mb-3" style="max-width: 700px;">
            <div class="row g-0">
                <div class="col-md-4">
                    <img src="@/assets/icone_apps_capes.jpg" class="img-fluid img-app rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <p class="card-text descricao-card">Acervo com mais de 45 mil títulos de produção científica nacional e internacional. <a href="https://www-periodicos-capes-gov-br.ez345.periodicos.capes.gov.br/index.php" target="_blank">Clique aqui</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-custom mb-3" style="max-width: 700px;">
            <div class="row g-0">
                <div class="col-md-4">
                    <img src="@/assets/icone_apps_complete_anatomy.jpg" class="img-fluid img-app rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <p class="card-text descricao-card">Acesse e aproveite o melhor da Anatomia Humana em 3D. <a href="https://aapa.uninove.br/seu/AAPA/biblioteca/como_acessar/Acesso%20Complete%20Anatomy.pdf" target="_blank">Clique aqui</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-custom mb-3" style="max-width: 700px;">
            <div class="row g-0">
                <div class="col-md-4">
                    <img src="@/assets/icone_apps_athena.jpg" class="img-fluid img-app rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <p class="card-text descricao-card">Plataforma com diferentes recursos e ferramentas com o melhor da Anatomia Veterinária. <a href="" target="_blank">Clique aqui</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-custom mb-3" style="max-width: 700px;">
            <div class="row g-0">
                <div class="col-md-4">
                    <img src="@/assets/icone_apps_treinamento.jpg" class="img-fluid img-app rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <p class="card-text descricao-card"> Agende sua capacitação e conheça todos os	recursos que a Biblioteca da UNINOVE oferece. São mais de 60 mil e-books e mais de 19 mil periódicos assinados. Você pode agendar através do e-mail: <a href="mailto:biblioteca10@uninove.br" target="_blank">biblioteca10@uninove.br</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-custom mb-3" style="max-width: 700px;">
            <div class="row g-0">
                <div class="col-md-4">
                    <img src="@/assets/icone_apps_contato_email.jpg" class="img-fluid img-app rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <p class="card-text descricao-card">Devido às restrições ao período de pandemia, o atendimento presencial está temporariamente suspenso. Entre em contato, através do e-mail: <a href="mailto:biblioteca10@uninove.br" target="_blank">biblioteca10@uninove.br</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    
</template>

<script>
export default {
    name: 'iconesApps',
    data(){
        return{
        }
    }
}
</script>


<style scoped>
.card-custom{
   background-color: transparent !important;
   border: none !important;
   transition: all .25s ease-in-out;
}
.img-app {
    border-radius: 10px 10px 10px 10px !important;
    width: 200px
}
.descricao-card{
    text-align: left;
    word-break: break-word;
}
.card-body a {
    word-break: normal;
}

</style>
